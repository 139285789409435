export const quoteStatusColors = {
  submitted: 'primary',
  waitingAgentResponse: 'secondary',
  waitingPrimaryUserApproval: 'warning',
  modificationRequested: 'warning',
  documentsRequested: 'warning',
  documentsSubmitted: 'warning',
  revalidationRequested: 'warning',
  expired: 'danger',
  rejected: 'danger',
  approved: 'success',
  archived: 'danger',
  awaitingReconfirmation: 'light-gray'
};
