<template>
  <div>
    <modal-form :title="$t(modalTitle)" :fields="modificationFields" :rules="modificationRules"
                :visible="modificationModalVisible" :cancelButton="cancelButton"
                :loading="loading" @forceUpdated="handleForceUpdate"
                @close-modal="closeModificationModal" @submit-form="submitForm" />
    <app-header
      :routes="routes"
      :buttons="buttons" @header-action="headerAction($event)"
    />
      <Main>
        <a-tabs v-model:activeKey="activeKey">
          <a-tab-pane key="details" :tab="$t('views.shipmentQuote.details')">
            <a-row :gutter="15">
              <a-col :sm="24" :md="24" :lg="10" :xl="8">
                <shipment-card v-if="shipmentQuote && shipmentQuote._jv && shipmentRequest && shipmentRequest._jv"
                  :rejectionReason="shipmentQuote && shipmentQuote.status === shipmentQuoteStatuses.REJECTED ?
                    shipmentQuote.rejectionReason : ''"
                  :shipment="shipmentQuote._jv.relationships.shipment"
                  :shipmentRequest="shipmentRequest"
                  :canRequestDocuments="canModifyQuote(shipmentQuote, shipmentQuote._jv?.relationships.shipment)"
                  :view="shipmentDetailsView.SUMMARY"
                  @request-documents="openModal('requestDocuments')" />
              </a-col>
              <a-col :sm="24" :md="24" :lg="14" :xl="16">
                <sdCards :title="$t('views.shipmentQuote.breakdown')"
                  :paymentTag="isClient && shipmentQuote.clientPaid || !isClient && shipmentQuote.paidToAgent"
                  :paymentTagColor="theme['primary-color']"
                  :tag="shipmentQuote.status ?
                  $t(`models.shipmentQuote.attributes.statuses.${shipmentQuote.status}`) : ''"
                  :tagColor="shipmentQuote.status ?
                    `${theme[quoteStatusColors[$_.camelCase(shipmentQuote.status)]+'-color']}` : ''"
                  :tagTooltip="shipmentQuote.status === shipmentQuoteStatuses.REJECTED ? shipmentQuote.rejectionReason :
                              ''">
                  <template #button v-if="shipmentQuote.submittedToClient && isClient || !isClient">
                    <sdButton v-if="canMarkTemplate && !hasQuoteTemplate" shape="circle"
                      class="btn-icon" type="default" @click="markAsTemplate" :tooltip="$t('actions.markAsTemplate')">
                      <sdFeatherIcons type="check" size="16" :stroke="theme['success-color']"/>
                    </sdButton>
                    <sdButton v-if="canRequestModifications" shape="circle" class="btn-icon" type="default"
                              @click="openModal('requestModifications')" :tooltip="$t('actions.requestModifications')">
                      <sdFeatherIcons type="flag" size="16" />
                    </sdButton>
                    <sdButton v-if="!isClient &&
                      shipmentQuote.status === shipmentQuoteStatuses.REVALIDATION_REQUESTED" shape="circle"
                              class="btn-icon" type="default" @click="revalidate"
                              :tooltip="$t('actions.revalidate')">
                      <sdFeatherIcons type="refresh-cw" size="16" :stroke="theme['success-color']"/>
                    </sdButton>
                    <sdButton
                      v-if="shipmentQuote.quotePdf"
                      shape="circle"
                      class="btn-icon"
                      type="default"
                      @click="downloadQuotePdf"
                      :tooltip="$t('actions.download')"
                    >
                      <sdFeatherIcons type="download" size="16" />
                    </sdButton>
                    <sdButton
                      v-if="shipmentQuote.quotePdf"
                      shape="circle"
                      class="btn-icon"
                      type="default"
                      @click="previewQuotePdf"
                      :tooltip="$t('actions.preview')"
                    >
                      <sdFeatherIcons type="navigation" size="16" />
                    </sdButton>
                    <sdButton v-if="agentCanApproveQuote(can, shipmentQuote) && !isClient"
                              shape="circle" class="btn-icon"
                              type="default" @click="approveQuote"
                              :tooltip="$t('actions.approve')">
                      <sdFeatherIcons type="check-circle" size="16" :stroke="theme['success-color']" />
                    </sdButton>
                  </template>
                  <quote-breakdown v-if="shipmentQuote.submittedToClient && isClient || !isClient"
                                   :quote="shipmentQuote" />
                  <p v-if="!shipmentQuote.submittedToClient && isClient">{{$t('messages.help.noQuoteSubmitted')}}</p>
                </sdCards>
              </a-col>
              <a-col v-if="!isClient" :sm="24" :md="24" :lg="allowViewShipmentQuotes ? 8 : 12">
                <sdCards class="modifications-card"
                          :title="$t('models.shipmentQuote.attributes.modificationsRequested')" >
                  <template v-if="shipmentQuote.modificationsRequested">
                    <p class="preserve-multiline">{{shipmentQuote.modificationsRequested}}</p>
                  </template>
                  <template v-else>
                    <a-empty>
                      <template #description>
                      <span>{{$t('views.shipmentQuote.noModifications')}}</span>
                    </template>
                    </a-empty>
                  </template>
                </sdCards>
              </a-col>
              <a-col :sm="24" :md="24"
                :lg="isClient ? 12 : allowViewShipmentQuotes ? 8 : 12">
                <sdCards class="modifications-card"
                          :title="$t('models.shipmentQuote.attributes.documentsRequested')" >
                  <template #button>
                    <sdButton v-if="canModifyQuote(shipmentQuote, shipmentQuote._jv?.relationships.shipment)"
                              shape="circle" class="btn-icon" type="default"
                              @click="openModal('requestDocuments')" :tooltip="$t('actions.requestDocuments')">
                      <sdFeatherIcons type="edit" size="16" />
                    </sdButton>
                  </template>
                  <template v-if="shipmentQuote.documentsRequested">
                    <p class="preserve-multiline">{{shipmentQuote.documentsRequested}}</p>
                  </template>
                  <template v-else>
                    <a-empty>
                      <template #description>
                      <span>{{$t('views.shipmentQuote.noDocuments')}}</span>
                    </template>
                    </a-empty>
                  </template>
                </sdCards>
              </a-col>
              <a-col :sm="24" :md="24"
                :lg="isClient ? 12 : allowViewShipmentQuotes ? 8 : 0">
                <file-upload-card ref="fileUploadCard"
                  v-if="isClient && shipmentQuote._jv &&
                  shipmentQuote._jv.relationships.shipment.status !== 'cancelled'"
                  :title="$t('views.shipmentRequest.shipmentDocuments')"
                  :buttonLoading="buttonLoading"
                  :fileList="shipmentQuote._jv.relationships.shipment.documents || []"
                  :multiple="true"
                  @uploadFiles="uploadDocuments"
                  @deleteFile="deleteDocument"/>
                <file-list-card
                  v-else-if="allowViewShipmentQuotes"
                  class="file-list-card"
                  :files="shipmentQuote._jv.relationships.shipment.documents || []"
                  :title="$t('models.shipment.attributes.documents')">
                </file-list-card>
              </a-col>
            </a-row>
          </a-tab-pane>
          <a-tab-pane v-if="can('read', 'shipmentRequestHistory')" key="history"
          :tab="$t('views.shipmentQuote.history')">
            <a-row :gutter="15">
              <a-col :xxl="24" :xl="24" :md="24" :xs="24">
                <sd-cards :title="$t('layout.shipmentRequest.history')">
                  <versions-table resourceName="shipment_quote" :resourceId="quoteId"  />
                </sd-cards>
              </a-col>
            </a-row>
          </a-tab-pane>
        </a-tabs>
      </Main>
  </div>
  <client-agent-chat v-if="shipmentQuote._jv && $can('manage', 'chat')"
                     :quote-id="shipmentQuote._jv?.id"
                     :quote-status="shipmentQuote?.status"/>
</template>

<script>
import {
  defineComponent, computed, ref, reactive, watch, onBeforeMount, onUnmounted
} from 'vue';
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {useI18n} from 'vue-i18n';
import _ from 'lodash';
import {shipmentQuoteStatuses, shipmentDetailsView} from '@/constants';
import {downloadFile} from '@/helpers/downloadFile';
import {previewFile} from '@/helpers/previewFile';
import {theme} from '@/config/theme/themeVariables';
import {quoteStatusColors} from '@/helpers/quoteStatusColors';
import {Main} from '@/components/shared/styledComponents/Main';
import ModalForm from '@/components/modals/ModalForm';
import QuoteBreakdown from '@/components/shipmentQuotes/QuoteBreakdown';
import ShipmentCard from '@/components/shipments/ShipmentCard';
import FileUploadCard from '@/components/shared/cards/FileUploadCard';
import FileListCard from '@/components/shared/cards/FileListCard';
import ClientAgentChat from '@/components/shipmentQuotes/ClientAgentChat';
import VersionsTable from '@/components/shipments/VersionsTable';
import {
  agentCanApproveQuote, clientCanApproveQuote, canRejectQuote,
  canModifyQuote, canViewDocuments
} from '@/helpers/quoteActionsConditions';
import {useAbility} from '@casl/vue';

export default defineComponent({
  name: 'ShipmentQuote',
  components: {
    Main,
    ModalForm,
    QuoteBreakdown,
    ShipmentCard,
    FileUploadCard,
    ClientAgentChat,
    FileListCard,
    VersionsTable
  },
  setup() {
    const {t} = useI18n();
    const route = useRoute();
    const router = useRouter();
    const {
      state, getters, dispatch
    } = useStore();

    const shipmentRequestId = computed(() => route.params.shipmentRequestId);
    const allowViewShipmentQuotes = computed(() =>
      shipmentQuote.value._jv && canViewDocuments(can, shipmentQuote.value._jv.relationships.shipment));

    const shipmentId = computed(() => route.params.shipmentId);
    const hasQuoteTemplate = computed(() => shipmentQuote?.value?.hasQuoteTemplate);
    const canMarkTemplate = computed(() => {
      const canNotMark = [shipmentQuoteStatuses.MODIFICATION_REQUESTED,
        shipmentQuoteStatuses.WAITING_PRIMARY_USER_APPROVAL];
      return can('read', 'shipmentQuoteTemplate') && !_.includes(canNotMark, shipmentQuote?.value?.status);
    });
    const quoteId = computed(() => route.params.quoteId);
    const isClient = computed(() => state.session.userType === 'ClientUser');
    const isAgent = computed(() => state.session.userType === 'AgentUser');
    const activeKey = ref('details');
    const cancelButton = {type: 'secondary', visible: true};
    const modalTitle = ref('actions.modify');
    const modalStates = {
      requestModifications: 0, requestDocuments: 1, reject: 2, approve: 3
    };
    const modalState = ref(modalStates.requestModifications);
    const modificationModalVisible = ref(false);

    const closeModificationModal = () => {
      modificationModalVisible.value = false;
      setModificationFields();
    };

    const modificationFields = reactive({
      modificationsRequested: {
        type: 'textArea',
        label: '',
        fieldValue: '',
        hidden: computed(() => modalState.value !== modalStates.requestModifications)
      },
      documentsRequested: {
        type: 'textArea',
        label: '',
        fieldValue: '',
        hidden: computed(() => modalState.value !== modalStates.requestDocuments)
      },
      reject: {
        type: 'textArea',
        placeholder: 'models.shipmentQuote.attributes.rejectionReason',
        fieldValue: '',
        hidden: computed(() => modalState.value !== modalStates.reject)
      },
      paymentPlanned: {
        type: 'datePicker',
        placeholder: 'models.shipmentQuote.attributes.plannedPayment',
        fieldValue: undefined,
        futureDate: true,
        hidden: computed(() => modalState.value !== modalStates.approve)
      }
    });
    const modificationRules = reactive({
      modificationsRequested: {
        required: computed(() => modalState.value === modalStates.requestModifications),
        message: t('messages.error.required'), trigger: 'change'
      },
      documentsRequested: {
        required: computed(() => modalState.value === modalStates.requestDocuments),
        message: t('messages.error.required'), trigger: 'change'
      },
      reject: {
        required: computed(() => modalState.value === modalStates.reject),
        message: t('messages.error.required'), trigger: 'change'
      },
      paymentPlanned: {
        required: computed(() => modalState.value === modalStates.approve),
        type: 'object',
        message: t('messages.error.required'), trigger: 'change'
      }
    });

    const openModal = (action) => {
      modalState.value = modalStates[action];
      modalTitle.value = `actions.${action}`;
      modificationModalVisible.value = true;
    };

    const shipmentQuote = computed(() => getters.shipmentQuote);
    const shipmentRequest = computed(() => getters.shipmentRequest);

    const {can} = useAbility();
    const canRequestModifications = computed(() => !isClient.value && can('approve', shipmentQuote.value));

    const loading = ref(false);
    const submitForm = (data) => {
      switch (modalState.value) {
      case modalStates.reject:
        return rejectQuote(data.reject);
      case modalStates.requestModifications:
      case modalStates.requestDocuments:
        return submitModification(data);
      case modalStates.approve:
        return approveQuote(data);
      }
    };

    const approveQuote = (formData) => {
      const form = {};
      loading.value = true;
      form[_.snakeCase('plannedPayment')] = formData ? formData.payment_planned : undefined;
      dispatch('approveShipmentQuote', {
        id: shipmentQuote.value._jv.id,
        form
      }).then(() => {
        loading.value = false;
        modificationModalVisible.value = false;
      });
    };

    const routeToEdit = () => {
      router.push({
        name: 'AgentEditQuoteForm',
        params: {
          id: quoteId.value,
          shipmentId: shipmentQuote.value._jv.relationships.shipment._jv.id,
          shipmentRequestId: shipmentQuote.value._jv.relationships.shipment._jv.relationships.shipmentRequest.data.id
        }
      });
    };

    const submitModification = (data) => {
      loading.value = true;
      dispatch('updateShipmentQuote', {
        id: shipmentQuote.value._jv.id, form: data, updateType: 'request'
      }).then(({status}) => {
        loading.value = false;
        if (status === 200) {
          modificationModalVisible.value = false;
          setModificationFields();
        }
      });
    };

    const downloadQuotePdf = () => {
      const quotePdf = shipmentQuote.value.quotePdf;
      downloadFile(quotePdf.filename, quotePdf.url);
    };

    const previewQuotePdf = () => {
      const quotePdfUrl = shipmentQuote.value.quotePdf.url;
      previewFile(quotePdfUrl);
    };

    const routes = computed(() => {
      if (route.name !== 'AgentQuote') {
        const breadcrumbs = [{
          name: isClient.value ? 'ClientShipmentRequests' : 'AgentShipments',
          breadcrumbName: isClient.value ? 'layout.shipmentRequest.index' : 'layout.shipmentRequest.shipments'
        },
        {
          name: isClient.value ? 'ClientShipmentRequestDetails' : 'AgentShipmentRequestDetails',
          params: {shipmentRequestId: shipmentRequestId.value},
          breadcrumbName: 'views.shipmentRequest.details'
        },
        {
          name: isClient.value ? 'ClientShipmentQuotes' : undefined,
          params: {shipmentRequestId: shipmentRequestId.value, shipmentId: shipmentId.value},
          breadcrumbName: 'views.shipmentQuote.quotes'
        },
        {
          name: isClient.value ? 'ClientShipmentQuote' : 'AgentShipmentQuote',
          params: {shipmentRequestId: shipmentRequestId.value, shipmentId: shipmentId.value},
          breadcrumbName: isClient.value ? 'views.breadcrumbs.identifier' : 'views.shipmentQuote.quoteWithIdentifier',
          paramName: 'identifier',
          paramValue: `${shipmentQuote.value.identifier}`
        }];

        if (!isClient.value) {
          breadcrumbs.splice(2, 1);
        }
        return breadcrumbs;
      } else {
        return [{
          name: 'AgentQuotes',
          breadcrumbName: 'views.shipmentQuote.index'
        },
        {
          name: 'AgentQuote',
          params: {quoteId: quoteId.value},
          breadcrumbName: 'views.breadcrumbs.identifier',
          paramName: 'identifier',
          paramValue: `${shipmentQuote.value.identifier}`
        }];
      }
    });

    const requestRevalidation = () => dispatch('requestQuoteRevalidation', shipmentQuote.value._jv.id);
    const revalidate = () => dispatch('revalidateShipmentQuote', shipmentQuote.value._jv.id);
    const markAsTemplate = async () => {
      await dispatch('toggleLoading', true);
      const status = await dispatch('markShipmentQuoteAsTemplate', shipmentQuote.value._jv.id);
      if (status === 200) await getQuote();

      await dispatch('toggleLoading', false);
    };
    const rejectQuote = (reason) => {
      loading.value = true;
      const key = _.snakeCase('rejectionReason');
      dispatch('rejectShipmentQuote', {id: shipmentQuote.value._jv.id, form: {[key]: reason}}).then((status) => {
        loading.value = false;
        if (status === 200) {
          closeModificationModal();
        }
      });
    };

    const fileUploadCard = ref();
    const buttonLoading = ref(false);

    const uploadDocuments = (documents) => {
      buttonLoading.value = true;
      const formData = new FormData();
      _.forEach(documents, (doc) => formData.append('documents[]', doc));
      dispatch('addDocumentsToShipment', {
        shipmentId: shipmentId.value,
        shipmentRequestId: shipmentRequestId.value,
        documents: formData, trigger: 'shipmentQuote'
      }).then((status) => {
        if (status === 200) {
          fileUploadCard.value.files = [];
          getQuote();
        }
        buttonLoading.value = false;
      });
    };

    const deleteDocument = ({id}) => {
      dispatch('deleteShipmentDocuments', {
        shipmentId: shipmentId.value,
        shipmentRequestId: shipmentRequestId.value,
        documents: [id], trigger: 'shipmentQuote'
      });
    };

    const buttons = computed(() => {
      const actions = [];
      if (isClient.value && shipmentQuote.value.submittedToClient) {
        if (clientCanApproveQuote(can, shipmentQuote.value)) {
          actions.push({
            action: 'approve',
            icon: 'check',
            label: 'actions.approve'
          });
        }

        if (canRejectQuote(can, shipmentQuote.value)) {
          actions.push({
            action: 'reject',
            icon: 'x-circle',
            label: 'actions.reject'
          });
        }

        if (can('requestRevalidation', shipmentQuote.value)) {
          actions.push({
            action: 'revalidate',
            icon: 'help-circle',
            label: 'actions.requestRevalidation'
          });
        }
      } else if (isAgent.value && shipmentQuote.value.status === shipmentQuoteStatuses.MODIFICATION_REQUESTED) {
        actions.push({
          action: 'routeToEdit',
          icon: 'edit',
          label: 'actions.edit'
        });
      }
      return actions;
    });

    const headerAction = (action) => {
      switch (action) {
      case 'approve':
        openModal('approve');
        break;
      case 'reject':
        openModal('reject');
        break;
      case 'routeToEdit':
        routeToEdit();
        break;
      default:
        requestRevalidation();
        break;
      }
    };


    const getQuote = async () => {
      dispatch('toggleLoading', true);
      if (quoteId.value) {
        await dispatch('getShipmentQuote', {id: quoteId.value});
      } else {
        await dispatch('getQuoteByShipment', {
          shipmentId: shipmentId.value,
          shipmentRequestId: shipmentRequestId.value
        });
      }

      setModificationFields();

      if (shipmentQuote.value.status !== shipmentQuoteStatuses.REJECTED) {
        dispatch('subscribeToMessagesChannel', shipmentQuote.value._jv.id);
      }
      if (shipmentQuote.value && shipmentQuote.value._jv) {
        const shipment = shipmentQuote.value._jv.relationships.shipment._jv;
        await dispatch('getShipmentRequest', shipment.relationships.shipmentRequest.data.id);
      }
      dispatch('toggleLoading', false);
    };

    const setModificationFields = () => {
      _.forEach(['documentsRequested', 'modificationsRequested'], (field) => {
        modificationFields[field].fieldValue = shipmentQuote.value[field];
        modificationFields[field]['forceUpdate'] = true;
      });
    };

    watch(() => [route.name, shipmentRequestId, shipmentId, quoteId], () => {
      if (_.includes(['ClientShipmentQuote', 'AgentQuote', 'AgentShipmentQuote'], route.name)) {
        getQuote();
      }
    }, {immediate: true, deep: true});

    onBeforeMount(() => dispatch('clearChat'));
    onUnmounted(() => dispatch('unsubscribeToMessagesChannel', shipmentQuote.value._jv.id));
    const handleForceUpdate = (keys) => _.forEach(keys, (key) => modificationFields[key]['forceUpdate'] = false);

    return {
      state,
      routes,
      buttons,
      modalTitle,
      isClient,
      isAgent,
      canRequestModifications,
      canModifyQuote,
      modificationRules,
      modificationFields,
      cancelButton,
      modificationModalVisible,
      shipmentRequest,
      shipmentQuote,
      agentCanApproveQuote,
      theme,
      quoteStatusColors,
      fileUploadCard,
      buttonLoading,
      shipmentQuoteStatuses,
      shipmentDetailsView,
      loading,
      canViewDocuments,
      routeToEdit,
      headerAction,
      openModal,
      closeModificationModal,
      submitForm,
      downloadQuotePdf,
      previewQuotePdf,
      approveQuote,
      revalidate,
      uploadDocuments,
      deleteDocument,
      handleForceUpdate,
      can,
      activeKey,
      allowViewShipmentQuotes,
      quoteId,
      hasQuoteTemplate,
      canMarkTemplate,
      markAsTemplate
    };
  }
});
</script>

<style lang="scss" scoped>
.ant-card {
  height: 94% !important
}
.quote-summary {
  margin-bottom: 0px !important;
}
.modification-helper {
  text-align: right;
  padding-right: 10px;
  padding-top: 10px;
  font-style: italic;
}
.modifications-card {
  min-height: 200px !important;
}
</style>
