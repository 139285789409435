<template>
    <sdCards :title="title">
      <a-row class="uploader-row">
        <a-form ref="formRef" :model="formState" class="uploader-form" :rules="{fileUpload: rules}">
          <a-col :xs="24" class="mt-20 mb-20">
            <div class="ml-10 mr-10">
              <a-form-item name="fileUpload">
                <a-upload-dragger :beforeUpload="beforeUpload" :remove="handleRemove"
                                  :file-list="files" :disabled="disabled"
                                  :multiple="multiple">
                  <p class="ant-upload-text">{{$t('views.fileUploadCard.helper')}}</p>
                </a-upload-dragger>
              </a-form-item>
            </div>
          </a-col>
          <a-col :xs="24">
            <a-button class="btn-outlined float-right" type="primary" size="default"
                      :disabled="files.length === 0 || buttonLoading"
                      :outlined="true"
                      :loading="buttonLoading"
                      @click="uploadFiles">
                {{$t('actions.save')}}
            </a-button>
          </a-col>
        </a-form>
      </a-row>
      <div>
        <file-list-card :files="fileList" title="" :canDelete="true"
          @deleteFile="$emit('deleteFile', $event)" />
      </div>
    </sdCards>
</template>

<script>
import {
  defineComponent, reactive, ref
} from 'vue';
import VueTypes from 'vue-types';
import {uploader} from '@/helpers/uploader';
import FileListCard from './FileListCard.vue';

export default defineComponent({
  name: 'FileUploadCard',
  components: {
    FileListCard
  },
  emits: ['uploadFiles', 'deleteFile'],
  props: {
    title: VueTypes.string.def(''),
    disabled: VueTypes.bool.def(false),
    multiple: VueTypes.bool.def(false),
    fileList: VueTypes.array.def([]),
    buttonLoading: VueTypes.bool.def(false)
  },
  setup(props, context) {
    const formRef = ref();

    const {
      files, beforeUpload, handleRemove, rules
    } = uploader(props.multiple, context);

    const formState = reactive({
      fileUpload: files
    });

    const uploadFiles = () => {
      formRef.value
        .validate()
        .then(() => {
          context.emit('uploadFiles', files.value);
        });
    };

    return {
      formRef,
      files,
      formState,
      rules,
      beforeUpload,
      handleRemove,
      uploadFiles
    };
  }
});
</script>

<style lang="scss" scoped>
.uploader-form .ant-row,
.uploader-row.ant-row {
  display: block !important;
}

.ant-form.uploader-form.ant-form-horizontal .ant-col {
  min-height: 40px !important;
}
</style>
